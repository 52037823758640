<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <text-field
            v-if="item.name === 'notes'"
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :stars-read-only="true"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-disabled
            :isIcon="item.name === 'notes' && !!getCurUserInfo[item.name]"
            :icon-name="
              getCurUserInfo[item.name] === 'valid_certificate'
                ? 'actual'
                : 'error-check'
            "
            isTranslate
            translateFrom="profile"
          />
          <text-field
            v-else
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :stars-read-only="true"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-disabled
            :isIcon="item.name === 'notes'"
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <div v-if="getQrCode" class="profile__qr-code-block">
      <h4 class="title-h4 profile__title">
        {{ $t('profile.user_qr_code') }}
      </h4>
      <div class="profile__qr-code" v-html="getQrCode" />
    </div>
    <h4 class="title-h4 eu-key__title">
      {{ $t('profile.electronic_key') }}
    </h4>
    <EuKey :isUploadKey="!isUserHasEuKey" class="mb-16" />
    <div class="profile__btn-wrapper">
      <Button
        v-if="getAuthUserData.certificate_history && getAuthUserData.certificate_history.attachment"
        iconName="arrow"
        isRightIcon
        class="profile__btn"
        :disabled="!isCertificate"
        @click.native="downloadCertificate"
      >
        {{ $t('buttons.download_certificate') }}
      </Button>
      <Button iconName="print" class="profile__btn" @click.native="printWindow">
        {{ $t('buttons.print') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { convertDateToCurrentTimeZone } from '@/library/time';
import profile from '../../models/hotel/profile';
import EuKey from '@/elements/euKey/EuKey.vue';
export default {
  components: { EuKey },

  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getAuthUserData');
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'profile');
  },

  computed: {
    ...mapGetters(['getAuthUserData', 'isUserHasEuKey']),
    getModel() {
      return profile;
    },

    getCurUserInfo() {
      let obj = {};
      if (!!this.getAuthUserData) {
        for (let key in this.getAuthUserData) {
          let val = !!this.getAuthUserData[key]
            ? this.getAuthUserData[key]
            : '-';
          if (key === 'star_category') {
            val = !!this.getAuthUserData[key] ? this.getAuthUserData[key] : 0;
          }
          obj[key] = `${val}`;
        }

        obj.title = !!this.getAuthUserData.certificate_history?.title
          ? this.getAuthUserData.certificate_history?.title
          : '-';

        obj.population_item = !!this.getAuthUserData.settlement?.public_name
          ? this.getAuthUserData.settlement.public_name
          : '-';

        obj.region = !!this.getAuthUserData.state?.public_name
          ? this.getAuthUserData.state.public_name
          : '-';

        obj.district = !!this.getAuthUserData.district?.public_name
          ? this.getAuthUserData.district.public_name
          : '-';

        obj.certificate_series = !!this.getAuthUserData.certificate_history
          ?.certificate_series
          ? this.getAuthUserData.certificate_history.certificate_series
          : '-';

        obj.certificate_number = !!this.getAuthUserData.certificate_history
          ?.certificate_number
          ? `${this.getAuthUserData.certificate_history.certificate_number}`
          : '-';

        obj.certificate_validity = !!this.getAuthUserData.certificate_history
          ?.certificate_validity
          ? this.getAuthUserData.certificate_history.certificate_validity
          : '-';

        obj.certificate_term_end = !!this.getAuthUserData.certificate_history
          ?.certificate_term_end
          ? convertDateToCurrentTimeZone(
              this.getAuthUserData.certificate_history.certificate_term_end
            ).date
          : '-';

        obj.certificate_term_start = !!this.getAuthUserData.certificate_history
          ?.certificate_term_start
          ? convertDateToCurrentTimeZone(
              this.getAuthUserData.certificate_history.certificate_term_start
            ).date
          : '-';

        obj.notes = this.getAuthUserData.certificate_history?.notes;

        obj.katottg = !!this.getAuthUserData.settlement?.katottg
          ? this.getAuthUserData.settlement.katottg
          : '-';
      }
      return obj;
    },

    isCertificate() {
      return !!this.getAuthUserData?.certificate_history;
    },

    getQrCode() {
      return this.getAuthUserData?.qr_code;
    },
  },

  methods: {
    printWindow() {
      window.print();
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getAuthUserData.certificate_history.attachment.original_url
      );
    },
  },
};
</script>
